import React, { useState, useEffect } from "react";
import UsageChart from "./UsageChart";
import { useApiKey } from "./ApiKeyContext";

const Overview = () => {
  const { selectedApiKey } = useApiKey();
  const [overviewData, setOverviewData] = useState({
    requests: "00",
    avgResponseCost: "$ 00.00",
    avgLatency: "00.00 ms",
    avgCreditsSpent: "$ 00.00",
    totalCreditsSpent: "$ 00.00",
    maxLatency: "00.00 ms",
  });

  const validApiKeys = [
    "gAAAAABnLIcWa9ShtiPlB5gjUGQ2unyb_QMJAFeUvkaxqbvmFbjspIBOh63x0J7GAW-y_ubRmQhg__tSi8qSDBBijrjnYAh5rOWqcLk1DxYulBxe5_EC9tvmAUqU_Z3RAL2aNJ1fxW6K",
    "gAAAAABnCCgzcL0rpDgmrOLupUFckJRurV-_uS5Cs0B2jIDeu2XHkWf4LRcHmly2THVtArWclwI-xA8lwsgZEXqEMZsyHTXve0IEr-SWH0dIzRcz5EyAGB-kauLIoow7w1M5rharwD40",
    "gAAAAABm_EUlN3yzcmESHOTdURdkOqmzSlgKwINUNPA1lSo_FbQ1KiqX3klwRKwZMm7qgY7UxzFa1pNg4ZGnPVfVumFge7LtIg59TxU28OU-z6klveDl-5xVmAZtscKLSUjAZetrd633",
    "gAAAAABnLfZpyt13GdHc-QkjrVBKJcbJcpwPx8rpxXG3C4qJfetXS_Gyn8-D5XAERsIssAUGOC3K8qherSfH-ONHOSuccNfuBzTWYlJaZoK22dusAvZDRB6CWWwIdCc8AYBdqr0eEeIo",
    "gAAAAABm_WO6vbNcd_XvBK0wLD8E1W8PadRVS2UwuEIjO-oOpoQs0KuNgbfSgHaTF_H_v6rKCS9FMtYxpYp8Ab8saX7hoUKbETKy7uuxZzJRi1ZOezAOjhmcSjNvPpzMmFN6jKstx1ol",
    "gAAAAABm-9DJvdX8rIW3J6RYH7B26zxWqSWRKvz-8gx5niTK8VE_msAVvuOQ-TRS5dYBhRhrOukjmuuvEyqH0qed1tpBjAMS4hg643Lt4Wh4dys76QRLBp1Z5KXvMrRdiLnW_mMc3eNo",
    "gAAAAABm-9CjcWsdn0DnLuALNqUz1Bjh5uk9kUKQFQ443kOx6cJeQWbsAtuLIT_sKbdXPBXUJzMcAFvZSX-JP4gYa-A-_9KtcDBvL5_nqrllqZUzB4TCYsxtjx9tzdBGPAfFTgp2zQ5W",
    "gAAAAABnL3tAepKHMRaTZ-LRog6mG9Rb43rVVK8Ag3fFoKT10qzuwR1O0CWLeO9WI29cYRq1-HQvh2u8SyvoS7TV5PEbM_cIhiyLBXeIhA3zC_9M0sYz7aZ4-rF0lrI8gXSrw5rYIBzS",
  ];

  const exampleData = [
    { credits_used: 18.24, latency_ms: 7.82 },
    { credits_used: 20.73, latency_ms: 9.21 },
    { credits_used: 11.45, latency_ms: 4.13 },
    { credits_used: 5.58, latency_ms: 4.87 },
    { credits_used: 7.92, latency_ms: 5.32 },
    { credits_used: 24.34, latency_ms: 8.64 },
    { credits_used: 9.57, latency_ms: 3.92 },
    { credits_used: 6.44, latency_ms: 4.55 },
    { credits_used: 23.68, latency_ms: 9.04 },
    { credits_used: 12.89, latency_ms: 5.24 },
    { credits_used: 8.73, latency_ms: 5.89 },
    { credits_used: 21.43, latency_ms: 8.35 },
    { credits_used: 5.97, latency_ms: 3.87 },
    { credits_used: 17.26, latency_ms: 7.19 },
    { credits_used: 14.56, latency_ms: 6.57 },
    { credits_used: 25.67, latency_ms: 8.75 },
    { credits_used: 15.35, latency_ms: 7.42 },
    { credits_used: 6.48, latency_ms: 3.96 },
    { credits_used: 24.93, latency_ms: 9.58 },
    { credits_used: 22.78, latency_ms: 6.92 },
    { credits_used: 10.36, latency_ms: 5.11 },
    { credits_used: 13.12, latency_ms: 5.43 },
    { credits_used: 9.45, latency_ms: 3.78 },
    { credits_used: 26.72, latency_ms: 9.32 },
    { credits_used: 18.96, latency_ms: 6.02 },
    { credits_used: 20.45, latency_ms: 7.33 },
    { credits_used: 11.67, latency_ms: 4.89 },
    { credits_used: 5.23, latency_ms: 4.42 },
    { credits_used: 9.85, latency_ms: 5.14 },
    { credits_used: 24.72, latency_ms: 8.45 },
    { credits_used: 8.92, latency_ms: 4.23 },
    { credits_used: 7.63, latency_ms: 4.76 },
    { credits_used: 22.31, latency_ms: 8.12 },
    { credits_used: 13.98, latency_ms: 5.52 },
    { credits_used: 9.29, latency_ms: 5.91 },
    { credits_used: 20.52, latency_ms: 7.67 },
    { credits_used: 6.38, latency_ms: 4.05 },
    { credits_used: 17.84, latency_ms: 7.07 },
    { credits_used: 13.25, latency_ms: 6.67 },
    { credits_used: 25.13, latency_ms: 8.61 },
    { credits_used: 14.56, latency_ms: 7.22 },
    { credits_used: 6.82, latency_ms: 4.12 },
    { credits_used: 24.03, latency_ms: 8.89 },
    { credits_used: 21.67, latency_ms: 6.94 },
    { credits_used: 10.92, latency_ms: 5.63 },
    { credits_used: 12.14, latency_ms: 5.92 },
    { credits_used: 9.87, latency_ms: 4.82 },
    { credits_used: 27.04, latency_ms: 9.15 },
  ];

  const calculateStats = (data) => {
    const totalCredits = data.reduce((acc, item) => acc + item.credits_used, 0);
    const avgLatency =
      data.reduce((acc, item) => acc + item.latency_ms, 0) / data.length;
    const maxLatency = Math.max(...data.map((item) => item.latency_ms));
    const totalRequests = data.length;
  
    const avgResponseCost = totalCredits / totalRequests;
    const savingsFactor = 0.07;
    const avgCreditsSpent = avgResponseCost * (1 - savingsFactor);
  
    return {
      requests: totalRequests.toString(),
      avgResponseCost: `$ ${avgResponseCost.toFixed(2)}`,
      avgLatency: `${avgLatency.toFixed(2)} ms`,
      avgCreditsSpent: `$ ${(avgCreditsSpent).toFixed(2)}`,
      totalCreditsSpent: `$ ${totalCredits.toFixed(2)}`,
      maxLatency: `${maxLatency.toFixed(2)} ms`,
    };
  };
  

  useEffect(() => {
    if (selectedApiKey && validApiKeys.includes(selectedApiKey)) {
      setOverviewData(calculateStats(exampleData));
    } else {
      setOverviewData({
        requests: "00",
        avgResponseCost: "$ 00.00",
        avgLatency: "00.00 ms",
        avgCreditsSpent: "$ 00.00",
        totalCreditsSpent: "$ 00.00",
        maxLatency: "00.00 ms",
      });
    }
  }, [selectedApiKey]);

  return (
    <div className="bg-gray-900 p-8 rounded-xl text-center shadow-lg transition-all text-gray-100">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
        {Object.entries(overviewData).map(([key, value]) => (
          <div
            key={key}
            className="bg-gray-800 p-4 rounded-lg shadow-lg transition-transform hover:scale-[1.02]"
          >
            <p className="text-lg font-medium capitalize text-gray-200">
              {key.replace(/([A-Z])/g, " $1")}
            </p>
            <p className="text-cyan-500 text-3xl font-semibold">{value}</p>
          </div>
        ))}
      </div>
      <div className="chart-section bg-gray-800 mt-8 rounded-xl shadow-lg">
        <UsageChart selectedApiKey={selectedApiKey} />
      </div>
    </div>
  );
};

export default Overview;
