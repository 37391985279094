import React, { useState } from "react";
import axios from "axios";

const CompilerEngineerIntern = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [resume, setResume] = useState(null);
  const [jobRole] = useState("Compiler Engineer Intern");
  const [isDragging, setIsDragging] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleResumeUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setResume(e.target.files[0]);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setResume(e.dataTransfer.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("job_role", jobRole);
    formData.append("resume", resume);

    try {
      await axios.post(
        "https://backend-service-775365341254.us-central1.run.app/upload-resume",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error("Error uploading resume:", error);
      alert("Failed to upload resume.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-black min-h-screen flex items-center justify-center pt-16 px-2">
      <div className="bg-black rounded-lg p-8 shadow-lg max-w-5xl w-full">
        <div className="text-gray-400 leading-relaxed">
          <h2 className="text-3xl text-center font-bold text-teal-500 mb-6">
            Compiler Engineer Intern
          </h2>
          <p className="text-gray-400 leading-relaxed">
            <h2 className="text-xl text-teal-500 font-bold mt-4 mb-2">
              About CrossGL:
            </h2>
            At CrossGL, we are committed to revolutionizing graphics programming
            through our innovative product. This universal shader language is
            designed to simplify shader development across platforms like
            Vulkan, DirectX, and Metal.
            <h2 className="text-xl text-teal-500 font-bold mt-4 mb-2">
              Role Overview:
            </h2>
            Join our team as a Compiler Engineer Intern and dive into the core
            of graphics programming. Engage with compiler theory, design, and
            implementation, contributing to refining the CrossGL compiler. This
            role tackles challenges that influence developers globally.
            <h2 className="text-xl text-teal-500 font-bold mt-4 mb-2">
              Responsibilities:
            </h2>
            - Design, develop, and optimize the CrossGL compiler.
            <br />
            - Collaborate on architecture and implementation strategies.
            <br />
            - Address complex language design and performance optimization
            issues.
            <br />
            - Write maintainable code and participate in code reviews.
            <br />- Stay updated with trends in compiler and graphics
            programming.
            <h2 className="text-xl text-teal-500 font-bold mt-4 mb-2">
              Qualifications:
            </h2>
            - Strong background in computer science or related fields.
            <br />
            - Interest in compiler construction.
            <br />
            - Proficient in C/C++ and familiar with shader languages (Vulkan,
            DirectX, Metal).
            <br />
            - Problem-solving abilities and attention to detail.
            <br />- Ability to work remotely and collaboratively.
            <h2 className="text-xl text-teal-500 font-bold mt-4 mb-2">
              What We Offer:
            </h2>
            - Be part of a groundbreaking product in graphics programming.
            <br />
            - Fully remote work culture.
            <br />
            - This is an unpaid internship with the potential to transition into
            a full-time paid role with equity options.
            <br />- Opportunities for professional growth.
            <h2 className="text-xl text-teal-500 font-bold mt-4 mb-2">
              Join Us:
            </h2>
            If you're ready to contribute to innovations in graphics
            programming, apply today.
          </p>
          <div className="max-w-lg mx-auto mt-20 px-4 sm:px-6 lg:px-8 rounded-lg shadow-lg">
            <h2 className="text-4xl sm:text-5xl font-thin text-center text-teal-500 my-10">
              Apply
            </h2>
            <form
              onSubmit={handleSubmit}
              className="space-y-6 sm:space-y-8 mb-16"
            >
              <div className="relative w-full">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="block w-full px-4 py-3 text-lg font-light text-cyan-400 bg-transparent border-2 border-gray-600 rounded-lg appearance-none focus:outline-none hover:ring-2 hover:ring-teal-500 placeholder-gray-400 placeholder:font-light"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="relative w-full">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="block w-full px-4 py-3 text-lg font-light text-cyan-400 bg-transparent border-2 border-gray-600 rounded-lg appearance-none focus:outline-none hover:ring-2 hover:ring-teal-500 placeholder-gray-400 placeholder:font-light"
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="relative w-full">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full px-4 py-3 text-lg font-light text-cyan-400 bg-transparent border-2 border-gray-600 rounded-lg appearance-none focus:outline-none hover:ring-2 hover:ring-teal-500 placeholder-gray-400 placeholder:font-light"
                  placeholder="Email Address"
                  required
                />
              </div>
              <div
                className={`relative mt-4 py-24 sm:py-32 text-lg border-2 border-dashed ${
                  isDragging ? "border-teal-500" : "border-gray-600"
                } rounded-lg bg-black text-center text-gray-400`}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragging(true);
                }}
                onDragLeave={() => setIsDragging(false)}
                onDrop={handleDrop}
              >
                {resume ? (
                  <p className="text-cyan-400 font-light">{resume.name}</p>
                ) : (
                  <p>Upload Resume (PDF)</p>
                )}
                <input
                  type="file"
                  accept=".pdf"
                  id="resume-upload"
                  onChange={handleResumeUpload}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
              {isSubmitting && (
                <p className="text-center text-teal-500 mb-4">
                  Please wait while we process your application.
                </p>
              )}
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-4 px-8 sm:px-10 py-2 font-semibold inline-block text-center ring-2 ring-teal-500 text-cyan-500 rounded-md relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-cyan-950 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-all before:transition-transform ease-in-out before:z-0"
                >
                  <span className="relative z-10">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompilerEngineerIntern;
