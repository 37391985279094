import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Overview from './Overview';
import APIKeys from './APIKeys';
import Billing from './Billing';

const DashboardLayout = () => {
  const [selectedSection, setSelectedSection] = useState('Overview');
  const [selectedApiKey, setSelectedApiKey] = useState(null); 

  const renderSection = () => {
    switch (selectedSection) {
      case 'Overview':
        return <Overview selectedApiKey={selectedApiKey} />; 
      case 'API Keys':
        return <APIKeys setSelectedApiKey={setSelectedApiKey} />;
      case 'Billing':
        return <Billing />;
      default:
        return <Overview />;
    }
  };

  return (
    <div className="flex min-h-screen bg-black text-gray-100">
      <Sidebar selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
      <main className="flex-1 py-20 px-10">
        {renderSection()}
      </main>
    </div>
  );
};

export default DashboardLayout;
