import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ scrollToSection, refs }) => {
  const [scrollY, setScrollY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isGraphicaOpen, setIsGraphicaOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isGraphicaDropdownOpen, setIsGraphicaDropdownOpen] = useState(false);
  const [isResourcesDropdownOpen, setIsResourcesDropdownOpen] = useState(false);
  const [dropdownTimeout, setDropdownTimeout] = useState(null);
  const [graphicaDropdownTimeout, setGraphicaDropdownTimeout] = useState(null);
  const [resourcesDropdownTimeout, setResourcesDropdownTimeout] =
    useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
    navigate("/login");
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setShowNavbar(currentScrollY <= scrollY);
    setScrollY(currentScrollY);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMouseEnter = (setDropdown) => {
    if (dropdownTimeout) clearTimeout(dropdownTimeout);
    setDropdown(true);
  };

  const handleMouseLeave = (setDropdown, timeoutSetter) => {
    setDropdownTimeout(setTimeout(() => setDropdown(false), 100));
  };

  const handleNavigation = (sectionRef) => {
    navigate("/");
    setTimeout(() => scrollToSection(sectionRef), 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (dropdownTimeout) clearTimeout(dropdownTimeout);
      if (graphicaDropdownTimeout) clearTimeout(graphicaDropdownTimeout);
      if (resourcesDropdownTimeout) clearTimeout(resourcesDropdownTimeout);
    };
  }, [
    scrollY,
    dropdownTimeout,
    graphicaDropdownTimeout,
    resourcesDropdownTimeout,
  ]);

  const toggleDropdown = (dropdown) => {
    if (dropdown === "graphica") {
      setIsGraphicaOpen(!isGraphicaOpen);
      setIsResourcesOpen(false); 
    } else if (dropdown === "resources") {
      setIsResourcesOpen(!isResourcesOpen);
      setIsGraphicaOpen(false); 
    }
  };

  return (
    <nav
      className={`fixed top-0 w-full z-50 text-white p-1 transition-transform ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      }`}
      style={{ backgroundColor: "rgb(17, 17, 17)" }}
    >
      <div className="flex justify-between items-center mx-auto px-4 py-2">
        <Link to="/">
          <div className="flex items-center cursor-pointer">
            <div
              className="bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/logo.png)`,
                width: "30px",
                height: "40px",
              }}
            ></div>
            <span className="mx-2 text-2xl font-semibold navbar-logo">
              CrossGL
            </span>
          </div>
        </Link>
        <div className="hidden md:flex items-center space-x-10 navbar-items">
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter(setIsDropdownOpen)}
            onMouseLeave={() =>
              handleMouseLeave(setIsDropdownOpen, setDropdownTimeout)
            }
          >
            <Link
              to="#"
              className="inline-flex items-center overflow-hidden hover:text-teal-300"
            >
              PRODUCTS
            </Link>
            {isDropdownOpen && (
              <div
                className="text-center absolute -left-4 right-2 z-10 mt-2 w-28 rounded-md border border-gray-100 bg-white dark:border-teal-600 dark:bg-black"
                role="menu"
              >
                <div className="p-2">
                  <Link
                    to="/dyson"
                    className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                    role="menuitem"
                  >
                    DYSON
                  </Link>
                  <div
                    className="relative"
                    onMouseEnter={() =>
                      handleMouseEnter(setIsGraphicaDropdownOpen)
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(
                        setIsGraphicaDropdownOpen,
                        setGraphicaDropdownTimeout
                      )
                    }
                  >
                    <Link
                      to="#"
                      className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                    >
                      GRAPHICA
                    </Link>
                    {isGraphicaDropdownOpen && (
                      <div className="absolute left-[105px] p-1 top-0 mt-0 w-32 rounded-md border border-gray-100 bg-white dark:border-teal-600 dark:bg-black">
                        <Link
                          to="/crossvl"
                          className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                          role="menuitem"
                        >
                          CrossVL
                        </Link>
                        <Link
                          to="/"
                          onClick={() => {
                            handleNavigation(refs.Translatorref);
                            setIsGraphicaDropdownOpen(false);
                            setIsDropdownOpen(false);
                          }}
                          className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                          role="menuitem"
                        >
                          CrossTL
                        </Link>
                        <Link
                          to="/"
                          onClick={() => {
                            handleNavigation(refs.Compilerref);
                            setIsGraphicaDropdownOpen(false);
                            setIsDropdownOpen(false);
                          }}
                          className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                          role="menuitem"
                        >
                          CrossGL Compiler
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter(setIsResourcesDropdownOpen)}
            onMouseLeave={() =>
              handleMouseLeave(
                setIsResourcesDropdownOpen,
                setResourcesDropdownTimeout
              )
            }
          >
            <Link
              to="#"
              className="inline-flex items-center hover:text-teal-300"
            >
              RESOURCES
            </Link>
            {isResourcesDropdownOpen && (
              <div
                className="absolute z-10 mt-2 w-28 -left-2 right-2 text-center p-2 rounded-md border dark:border-teal-600 dark:bg-black"
                role="menu"
              >
                <Link
                  to="/blogs"
                  className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                  role="menuitem"
                >
                  BLOG
                </Link>
                <a
                  href="https://crossgl.github.io/crossgl-docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                  role="menuitem"
                >
                  DOCS
                </a>
                <a
                  href="https://github.com/CrossGL"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                  role="menuitem"
                >
                  GITHUB
                </a>
              </div>
            )}
          </div>

          <Link to="/contact" className="hover:text-teal-300">
            CONTACT
          </Link>
        </div>

          <div className="hidden md:flex items-center space-x-6 navbar-items">
          {isLoggedIn && (
            <Link
              to="/dashboard"
              className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
            >
              Dashboard
            </Link>
          )}
          {isLoggedIn ? (
            <button
              onClick={handleLogout}
              className="px-5 py-2 border border-red-600 text-red-600 rounded hover:border-red-800 hover:text-red-300 hover:bg-red-800"
            >
              Logout
            </button>
          ) : (
            <Link
              to="/login"
              className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
            >
              Login
            </Link>
          )}
        </div>

        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMobileMenu}
            className="text-teal-600 hover:text-teal-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        <div
          className={`md:hidden flex flex-col items-center space-y-4 py-4 transition-all duration-300 ease-in-out ${
            isMobileMenuOpen
              ? "navbar-items-enter-active"
              : "navbar-items-exit-active navbar-collapsed"
          } absolute top-full border-b-4 rounded-b-xl border-blue-400 left-0 w-full bg-gray-900 z-40`}
        >
          <Link
            to="/dyson"
            onClick={() => setIsMobileMenuOpen(false)}
            className="text-teal-500 font-semibold border-2 rounded-xl border-gray-700 w-60 text-center  px-4 py-2"
          >
            DYSON
          </Link>

          <div className="text-center w-full">
            <button
              onClick={() => toggleDropdown("graphica")}
              className="text-teal-500 font-semibold text-center border-2 rounded-xl border-gray-700 w-60 px-4 py-2"
            >
              GRAPHICA
            </button>
            <div
              className={`pl-4 mt-2 flex flex-col items-center transition-all duration-200 ease-in-out ${
                isGraphicaOpen
                  ? "opacity-100 max-h-screen"
                  : "opacity-0 max-h-0 overflow-hidden"
              }`}
            >
              <Link
                to="/crossvl"
                onClick={() => setIsMobileMenuOpen(false)}
                className={`block text-blue-400 font-semibold py-2 hover:text-teal-300 text-center transition-opacity duration-500 ease-in-out delay-100 ${
                  isGraphicaOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                CrossVL
              </Link>
              <Link
                to="/"
                onClick={() => {
                  handleNavigation(refs.Translatorref);
                  setIsMobileMenuOpen(false);
                }}
                className={`block text-blue-400 font-semibold py-2 hover:text-teal-300 text-center transition-opacity duration-500 ease-in-out delay-200 ${
                  isGraphicaOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                CrossTL
              </Link>
              <Link
                to="/"
                onClick={() => {
                  handleNavigation(refs.Compilerref);
                  setIsMobileMenuOpen(false);
                }}
                className={`block text-blue-400 font-semibold py-2 hover:text-teal-300 text-center transition-opacity duration-500 ease-in-out delay-300 ${
                  isGraphicaOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                CrossGL Compiler
              </Link>
            </div>
          </div>

          <div className="text-center w-full">
            <button
              onClick={() => toggleDropdown("resources")}
              className="text-teal-500 font-semibold text-center border-2 rounded-xl border-gray-700 w-60 px-4 py-2"
            >
              RESOURCES
            </button>
            <div
              className={`pl-4 mt-2 flex flex-col items-center transition-all duration-200 ease-in-out ${
                isResourcesOpen
                  ? "opacity-100 max-h-screen"
                  : "opacity-0 max-h-0 overflow-hidden"
              }`}
            >
              <Link
                to="/blogs"
                onClick={() => setIsMobileMenuOpen(false)}
                className={`block text-blue-400 font-semibold py-2 hover:text-teal-300 text-center transition-opacity duration-500 ease-in-out delay-100 ${
                  isResourcesOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                BLOG
              </Link>
              <a
                href="https://crossgl.github.io/crossgl-docs/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsMobileMenuOpen(false)}
                className={`block text-blue-400 font-semibold py-2 hover:text-teal-300 text-center transition-opacity duration-500 ease-in-out delay-200 ${
                  isResourcesOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                DOCS
              </a>
              <a
                href="https://github.com/CrossGL"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsMobileMenuOpen(false)}
                className={`block text-blue-400 font-semibold py-2 hover:text-teal-300 text-center transition-opacity duration-500 ease-in-out delay-300 ${
                  isResourcesOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                GITHUB
              </a>
            </div>
          </div>

          <Link
            to="/contact"
            onClick={() => setIsMobileMenuOpen(false)}
            className="text-teal-500 font-semibold text-center border-2 rounded-xl border-gray-700 w-60 px-4 py-2"
          >
            CONTACT
          </Link>

          <div className="flex flex-col gap-2 justify-center text-center my-4">
            {isLoggedIn ? (
              <>
                <Link
                  to="/dashboard"
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
                >
                  Dashboard
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="px-5 py-2 border border-red-600 text-red-600 rounded hover:border-red-800 hover:text-red-300 hover:bg-red-800"
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/login"
                onClick={() => setIsMobileMenuOpen(false)}
                className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
