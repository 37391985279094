import React from "react";

const Sidebar = ({ selectedSection, setSelectedSection }) => {
  return (
    <aside className="w-64 bg-gray-900 text-gray-300 flex flex-col justify-between shadow-xl rounded-tr-2xl rounded-br-2xl">
      <div className="py-20 px-5">
        <nav className="mt-8">
          <ul className="space-y-3 text-center text-lg">
            {["Overview", "API Keys", "Billing"].map((section) => (
              <li
                key={section}
                className={`cursor-pointer bg-black ring-2 hover:bg-gray-800 rounded-md px-4 py-2 ${
                  selectedSection === section
                    ? "text-blue-400 bg-gray-800 ring-2 ring-red-800 font-semibold"
                    : "text-cyan-300 transition-colors duration-200"
                }`}
                onClick={() => setSelectedSection(section)}
              >
                {section}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};


export default Sidebar;
