import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Lottie from "lottie-react";
import matrixAnimation from "../../lottie_matrix.json"; 

export default function Login() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://backend-service-775365341254.us-central1.run.app/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);

        const redirectTo = location.state?.redirectTo || "#/dashboard";
        
        window.open(redirectTo, "_blank");
        
      } else {
        if (data.detail === "Invalid credentials") {
          setErrorMessage("Credentials not found");
        } else {
          setErrorMessage(data.detail || "Login failed");
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred during login.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section className="bg-black mt-5">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6 hidden lg:block">
          <Lottie
            animationData={matrixAnimation}
            loop={true}
            className="absolute inset-0 h-full w-full object-cover"
          />
        </aside>

        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">
            <h1 className="mt-6 text-2xl text-center font-bold text-cyan-700 sm:text-3xl md:text-4xl">
              Log In to CrossGL
            </h1>

            <p className="mt-4 text-center leading-relaxed text-gray-400">
              Enter your details to access your account.
            </p>

            <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-6 gap-6">
              {errorMessage && (
                <p className="col-span-6 text-red-500">{errorMessage}</p>
              )}

              <div className="col-span-6">
                <label htmlFor="Email" className="block text-sm font-medium text-gray-200">
                  Email
                </label>
                <input
                  type="email"
                  id="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 w-full h-10 px-2 rounded-md border-gray-700 bg-gray-800 text-sm text-gray-200 shadow-sm"
                  required
                />
              </div>

              <div className="col-span-6">
                <label htmlFor="Password" className="block text-sm font-medium text-gray-200">
                  Password
                </label>
                <input
                  type="password"
                  id="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="mt-1 w-full h-10 px-2 rounded-md border-gray-700 bg-gray-800 text-sm text-gray-200 shadow-sm"
                  required
                />
              </div>

              <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                <button
                  type="submit"
                  className="z-10 shrink-0 font-medium bg-cyan-100 text-center text-black py-3 px-8 rounded-xl relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-cyan-700 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
                >
                  <span className="relative z-10">Login</span>
                </button>

                <p className="mt-4 text-sm text-gray-400 sm:mt-0">
                  Don't have an account?{" "}
                  <a href="#/signup" className="text-gray-200 underline">
                    Sign Up
                  </a>
                  .
                </p>
              </div>
            </form>
          </div>
        </main>
      </div>
    </section>
  );
}
