import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import matrixAnimation from "../../lottie_matrix.json"; 

export default function Signup() {
  const [formData, setFormData] = useState({
    username: "", 
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if the password is at least 8 characters
    if (formData.password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }
  
    // Check if the email contains the "@" symbol
    if (!formData.email.includes("@")) {
      setErrorMessage("Email must contain '@'.");
      return;
    }
  
    try {
      const response = await fetch("https://backend-service-775365341254.us-central1.run.app/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      // Check if the response status is 500 or no response
      if (!response || response.status === 500) {
        setErrorMessage("An error occurred. Please try again later.");
        navigate("/login");
        return;
      }

      const data = await response.json();
  
      if (response.ok) {
        setSuccessMessage("Signup successful! Redirecting...");
        setTimeout(() => navigate("/login"), 1500);
      } else {
        // Ignore all other error responses and redirect to login
        setSuccessMessage("Signup successful! Please Login.");
        navigate("/login");
      }
    } catch (error) {
      // Handle other errors, redirecting as needed
      setErrorMessage("An error occurred. Redirecting to login.");
      navigate("/login");
    }
  };
    
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <section className="bg-black text-white mt-5">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6 hidden lg:block">
          <Lottie
            animationData={matrixAnimation}
            loop={true}
            className="absolute inset-0 h-full w-full object-cover"
          />
        </aside>

        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">
            <h1 className="mt-6 text-2xl font-bold text-center text-cyan-500 sm:text-3xl md:text-4xl">
              Welcome to CrossGL
            </h1>
            <p className="mt-4 text-center leading-relaxed text-gray-400">
              One Platform. Any Hardware. Infinite Possibilities.
            </p>

            <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-6 gap-6">
              {errorMessage && <p className="col-span-6 text-red-500">{errorMessage}</p>}
              {successMessage && <p className="col-span-6 text-green-500">{successMessage}</p>}

              <div className="col-span-6">
                <label htmlFor="Username" className="block text-sm font-medium text-gray-200">
                  Username
                </label>
                <input
                  type="text"
                  id="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="mt-1 w-full h-10 rounded-md border-gray-700 px-2 bg-gray-800 text-sm text-gray-200 shadow-sm"
                  required
                />
              </div>

              <div className="col-span-6">
                <label htmlFor="Email" className="block text-sm font-medium text-gray-200">
                  Email
                </label>
                <input
                  type="email"
                  id="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 w-full h-10 rounded-md border-gray-700 px-2 bg-gray-800 text-sm text-gray-200 shadow-sm"
                  required
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="Password" className="block text-sm font-medium text-gray-200">
                  Password
                </label>
                <input
                  type="password"
                  id="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="mt-1 w-full h-10 rounded-md border-gray-700 px-2 bg-gray-800 text-sm text-gray-200 shadow-sm"
                  required
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="PasswordConfirmation" className="block text-sm font-medium text-gray-200">
                  Confirm password
                </label>
                <input
                  type="password"
                  id="PasswordConfirmation"
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                  className="mt-1 w-full h-10 rounded-md border-gray-700 px-2 bg-gray-800 text-sm text-gray-200 shadow-sm"
                  required
                />
              </div>

              <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                <button
                  type="submit"
                  className="relative z-10 shrink-0 font-medium bg-cyan-100 text-center text-black py-3 px-8 rounded-full relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-cyan-700 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
                >
                  <span className="relative z-10">Create an account</span>
                </button>

                <p className="mt-4 text-sm text-gray-400 sm:mt-0">
                  Already have an account?{" "}
                  <a href="#/login" className="text-gray-200 underline">
                    Log in
                  </a>
                  .
                </p>
              </div>
            </form>
          </div>
        </main>
      </div>
    </section>
  );
}
