import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useInView } from "framer-motion";

const CareerPage = () => {
  // Function to create a fade-in animation when an element comes into view
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="bg-black text-white min-h-screen mt-16 py-12">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto text-center px-4">
        <motion.h1
          initial="hidden"
          whileInView="visible"
          variants={fadeIn}
          transition={{ duration: 0.5 }}
          className="text-4xl text-red-600 font-bold mb-2"
        >
          Join CrossGL Community
        </motion.h1>

        <motion.h3
          initial="hidden"
          whileInView="visible"
          variants={fadeIn}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-4xl text-cyan-600 font-light mb-20"
        >
          Innovating Together in AI, Graphics, and Hardware Optimization
        </motion.h3>
      </div>

      {/* Core Values Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        transition={{ duration: 1 }}
        className="w-full my-48 py-16 bg-gradient-to-r from-cyan-900 via-gray-800 to-red-900 relative overflow-hidden"
      >
        <div className="absolute inset-0 bg-black opacity-30 filter blur-xl"></div>
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.p
            initial="hidden"
            whileInView="visible"
            variants={fadeIn}
            transition={{ duration: 0.5 }}
            className="text-center text-3xl md:text-4xl font-bold text-white mb-6"
          >
            At CrossGL, innovation is driven by community.
          </motion.p>
          <motion.p
            initial="hidden"
            whileInView="visible"
            variants={fadeIn}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-center text-xl md:text-2xl font-light text-gray-300"
          >
            We're more than just a company, we're a group of passionate
            individuals working together to shape the future of AI-driven
            hardware optimization and cross-platform graphics development.
          </motion.p>
        </div>
      </motion.div>

      {/* Main Section */}
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeIn}
          transition={{ duration: 0.8 }}
          className="flex flex-col lg:flex-row items-start mb-28"
        >
          <div className="lg:w-1/2 lg:pr-12 mb-10 lg:mb-0">
            <h3 className="text-3xl md:text-4xl text-left lg:pt-28 mb-6">
              <span className="text-cyan-500 font-light">Be a part of something bigger at </span>
              <span className="text-red-600">CrossGL</span>
            </h3>
          </div>
          <div className="lg:w-1/2">
            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={fadeIn}
              transition={{ duration: 0.5 }}
              className="text-lg text-gray-300 font-light mb-6"
            >
              With our groundbreaking tools, Dyson and Graphica, we're
              empowering businesses to tackle inefficiencies in cloud
              infrastructure and graphics development.
            </motion.p>
            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={fadeIn}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-lg text-gray-300 font-light mb-6"
            >
              Solving complex problems takes collaboration, creativity, and a shared vision.
              At CrossGL, you won't just be joining a team—you'll be joining a
              network of innovators and creators, all working towards a common
              goal of transforming AI and graphics.
            </motion.p>
            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={fadeIn}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="text-lg text-gray-300 font-light"
            >
              We value diverse perspectives, continuous learning, and the spirit of collaboration
              that drives real change. Join us in shaping the future of technology.
            </motion.p>
          </div>
        </motion.div>

        {/* Explore Open Roles */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeIn}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="max-w-6xl my-10 text-4xl text-cyan-500 font-medium mx-auto border-b pb-10 border-gray-600 text-center"
        >
          Explore Open Roles
        </motion.div>

        {/* Job Roles */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeIn}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="max-w-6xl mx-auto py-6 border-b border-gray-600"
        >
          <h2 className="text-2xl font-bold text-white">ML Engineer Intern</h2>
          <p className="text-gray-300 mt-2">
            Help build and optimize machine learning models to improve hardware
            routing.
          </p>
          <Link
            to="/mle-intern"
            className="scroll-down mt-4 py-2 px-6 text-sm inline-block font-medium bg-black text-cyan-500 border hover:text-cyan-400 border-cyan-500 hover:border-cyan-400 rounded-md relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-cyan-900 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
          >
            <span className="relative z-10">Job Details</span>
          </Link>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeIn}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="max-w-6xl mx-auto py-6"
        >
          <h2 className="text-2xl font-bold text-white">Compiler Engineer Intern</h2>
          <p className="text-gray-300 mt-2">
            Work on designing and enhancing our custom compiler for optimizing
            hardware performance.
          </p>
          <Link
            to="/compiler-intern"
            className="scroll-down mt-4 py-2 px-6 text-sm inline-block font-medium bg-black text-cyan-500 border hover:text-cyan-400 border-cyan-500 hover:border-cyan-400 rounded-md relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-cyan-900 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
          >
            <span className="relative z-10">Job Details</span>
          </Link>
        </motion.div>

        <div className="max-w-6xl mx-auto border-b border-gray-600 mt-10 text-center"></div>
      </div>
    </div>
  );
};

export default CareerPage;
