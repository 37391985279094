import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Hero from "./pages/Hero";
import Contact from "./pages/Contact";
import Translator from "./pages/Translator";
import Compiler from "./pages/Compiler";
import Navbar from "./components/ui/Navbar";
import Footer from "./components/ui/Footer";
import Blogexplorer from "./pages/Blog/Blogexplorer";
import Blog from "./pages/Blog/Blog";
import DysonPage from "./pages/Dyson";
import Pricing from "./pages/Pricing";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ProtectedRoute from "./components/ui/ProtectedRoute";
// import DysonDB from "./pages/DysonDB";
import { useRef } from "react";
import { GlobalCanvas, SmoothScrollbar } from "@14islands/r3f-scroll-rig";
import MLEngineerIntern from "./pages/Career/MLEngineerIntern";
import CompilerEngineerIntern from "./pages/Career/CompilerEngineerIntern";
import FormPage from "./pages/Career/FormPage";
import CareerPage from "./pages/Career/CareerPage";
import { ApiKeyProvider } from "./pages/Dashboard/ApiKeyContext"; // Import the provider
import APIKeys from "./pages/Dashboard/APIKeys"; // Your API keys component
import Overview from "./pages/Dashboard/Overview";

function MainContent({ Translatorref, Compilerref }) {
  return (
    <div className="overflow-hidden">
      <GlobalCanvas style={{ pointerEvents: "none" }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
      </GlobalCanvas>
      <SmoothScrollbar>
        {(bind) => (
          <article {...bind}>
            <Hero />
            <Translator Translatorref={Translatorref} />
            <Compiler Compilerref={Compilerref} />
          </article>
        )}
      </SmoothScrollbar>
    </div>
  );
}

function App() {
  const Translatorref = useRef(null);
  const Compilerref = useRef(null);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Router>
      <ApiKeyProvider>
        {/* Wrapping the parts that use the API key context */}
        <Navbar
          scrollToSection={scrollToSection}
          refs={{ Translatorref, Compilerref }}
        />
        <Routes>
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blogexplorer />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/dyson" element={<DysonPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          {/* <Route path="/dysondb" element={<DysonDB />} /> */}
          <Route path="/hiring" element={<CareerPage />} />
          <Route path="/mle-intern" element={<MLEngineerIntern />} />
          <Route path="/compiler-intern" element={<CompilerEngineerIntern />} />
          {/* <Route path="/apply" element={<FormPage />} /> */}

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="*"
            element={
              <MainContent
                Translatorref={Translatorref}
                Compilerref={Compilerref}
              />
            }
          />

          {/* API Keys and Overview should be inside the routes */}
          <Route path="/api-keys" element={<APIKeys />} />
          <Route path="/overview" element={<Overview />} />
        </Routes>
        <Footer
          scrollToSection={scrollToSection}
          refs={{ Translatorref, Compilerref }}
        />
      </ApiKeyProvider>
    </Router>
  );
}

export default App;
